import { useCallback, useState, useRef } from 'react'
import QrReader from 'react-qr-reader'
import qs from 'query-string'
import { parseOTPQRData } from '@iq/iq-core'
import { noop, Message, Button } from '@iq/iq-ui-kit'
import useProps from '../hooks/useProps'
import { sendScanData } from '../api/firebase'
import useTabActive from '../hooks/useTabActive'

export function actionToTitle({ action, meta }) {
  let title

  switch (action) {
    case 'login':
      title = 'Авторизация'
      break
    case 'delete':
      title = 'Удаление'
      break
    case 'create':
      title = 'Создание'
      break
    case 'update':
      title = 'Обновление'
      break
    default:
      title = action
      break
  }

  if (meta) {
    title += ` (${ meta.ip || meta.message || meta.from })`
  }

  return title
}

const QRCodeScanner = () => {
  const active = useTabActive()

  const [result, setResult] = useState({})
  const { coords } = useProps()
  const lastData = useRef()

  const handleScan = useCallback(async (value) => {
    if (!value) return
    if (lastData.current === value) return
    lastData.current = value

    if (!value.startsWith('https://2fa.iqueue.uz/?org=')) {
      Message({
        timeout: 3000,
        title: 'QR value',
        subtitle: value,
      })
      return
    }

    try {
      const { query: { org, data } } = qs.parseUrl(value)
      const result = parseOTPQRData(org + '', data + '')

      await sendScanData({
        ...coords,
        ...result,
      })

      setResult(result)
    } catch (e) {
      console.error(e)
      setResult({
        otp: 'ERROR',
        action: e.message,
      })
    }
  }, [coords])

  return <div className={ 'qrcode-container' }>
    { active && !result.otp && <QrReader
      onScan={ (value) => handleScan(value).catch(console.error) }
      onError={ noop }
    /> }

    <div className={ 'scan-info' }>
      { result.otp ? <>
        <h1 style={ {
          color: result.otp === 'ERROR' ? 'var(--iq-error)' : 'var(--iq-color)'
        } }>{ result.otp }</h1>
        <h6>{ actionToTitle(result) }</h6>
      </> : <h1>?????</h1> }

      { coords && <p>{ coords.lat + ', ' + coords.lng }</p> }
    </div>

    { result.otp && <Button
      primary
      style={ {
        marginTop: '8rem'
      } }
      title={ 'Сканировать еще раз' }
      onClick={ () => {
        lastData.current = null
        setResult({})
      } }
    /> }

  </div>
}

export default QRCodeScanner
