import { createContext, useState, useEffect, useContext, useCallback } from "react"
import { ls } from '@iq/iq-ui-kit'
import { append, uniq, pipe, without } from 'ramda'
import {
  ERROR_OFFLINE,
  ERROR_NO_GPS,
  ERROR_NO_CAMERA,
  ERROR_UNSECURED_CONNECTION,
  ERROR_ACCOUNT_NO_PERMISSIONS,
} from '../components/Error'
import moment from 'moment'

const AppContext = createContext({})

const cache = {
  route: ls('last-route') || 'scanner',
  prevRoutes: ls('route-history') || [],
  user: ls('account') || null,
}

export function ContextProvider({ children }) {
  const [route, setRoute] = useState(cache.route)
  const [prevRoutes, setPrevRoutes] = useState(cache.prevRoutes)
  const [user, setUser] = useState(cache.user)
  const [coords, setCoords] = useState(null)
  const [globalError, setGlobalError] = useState([])
  const [historyDate, setHistoryDate] = useState(moment())

  const nextDay = useCallback(() => {
    setHistoryDate(date => moment(date).add(1, 'days'))
  }, [setHistoryDate])

  const prevDay = useCallback(() => {
    setHistoryDate(date => moment(date).subtract(1, 'days'))
  }, [setHistoryDate])

  const setFullDate = useCallback((date) => {
    setHistoryDate(moment(date))
  }, [setHistoryDate])

  const addGlobalError = useCallback((error) => {
    setGlobalError(pipe(append(error), uniq))
  }, [globalError])

  const removeGlobalError = useCallback((error) => {
    setGlobalError(without([error]))
  }, [globalError])

  useEffect(() => {
    ls('last-route', route)
  }, [route])

  useEffect(() => {
    ls('route-history', prevRoutes)
  }, [prevRoutes])

  useEffect(() => {
    if (!user) setGlobalError([])
    ls('account', user)
  }, [user])

  useEffect(() => {
    let mounted = true
    if (user && window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      addGlobalError(ERROR_UNSECURED_CONNECTION)
    }

    if (user && !navigator.mediaDevices) addGlobalError(ERROR_NO_CAMERA)

    if (user) {
      if (!user.role) addGlobalError(ERROR_ACCOUNT_NO_PERMISSIONS)

      navigator.geolocation.getCurrentPosition((res) => {
        if (!mounted) return
        console.log('success', res)
        removeGlobalError(ERROR_NO_GPS)
        setCoords({
          lat: res.coords.latitude,
          lng: res.coords.longitude,
          accuracy: res.coords.accuracy,
        })
      }, () => {
        if (!mounted) return
        addGlobalError(ERROR_NO_GPS)
      })
    }

    return () => {
      mounted = false
    }
  }, [user])

  const updateOnlineStatus = useCallback(() => {
    if (!navigator.onLine) {
      addGlobalError(ERROR_OFFLINE)
    } else {
      removeGlobalError(ERROR_OFFLINE)
    }
  }, [])

  useEffect(() => {
    updateOnlineStatus()
    // addGlobalError(ERROR_NO_CAMERA)
  }, [updateOnlineStatus])

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [updateOnlineStatus])

  return <AppContext.Provider value={ {
    coords,
    user, setUser,
    route, setRoute,
    prevRoutes, setPrevRoutes,
    historyDate, nextDay, prevDay, setFullDate,
    globalError, addGlobalError, removeGlobalError,
  } }>
    { children }
  </AppContext.Provider>
}

export default function useProps() {
  return useContext(AppContext)
}
