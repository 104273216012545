import { Image } from "@iq/iq-ui-kit"
import moment from 'moment'
import { actionToTitle } from '../views/QRCodeScanner'

export default function historyToItem(item) {
  const { id, action, meta, user_title, user_photo, systemTime } = item

  return {
    key: id,
    title: user_title,
    subtitle: actionToTitle({ action, meta }) + ', ' + moment(systemTime).calendar(),
    avatar: <Image
      placeholder={ 'AA' }
      style={ {
        background: 'var(--iq-divider)',
        color: 'var(--iq-color)',
        width: '4rem',
        height: '4rem',
        fontSize: '1.6rem',
        fontWeight: 700,
        borderRadius: '50%',
      } }
      src={ user_photo }
    />,
  }
}
