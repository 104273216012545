import { useCallback, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase, ref, get, push, set, onValue } from 'firebase/database'
import { noop } from '@iq/iq-ui-kit'
import moment from 'moment'
import { prop, mapObjIndexed } from 'ramda'
import { UUID } from '@iq/iq-core'

import {
  getAuth,
  signOut,
  setPersistence,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import fbObjectToArray from '../mappers/fbToObjectArray'
import bus from '../events/bus'
import EMessage from '../events/eMessage'
import useProps from '../hooks/useProps'
import { ERROR_ACCOUNT_NO_PERMISSIONS, ERROR_ACCOUNT_NOT_ACTIVATED } from '../components/Error'

// noinspection SpellCheckingInspection
const firebaseConfig = {
  apiKey: "AIzaSyBD_ztKSrSX3TgbtCb0bUW4wHkSlKlIbsU",
  authDomain: "iqueue-2fa.firebaseapp.com",
  projectId: "iqueue-2fa",
  storageBucket: "iqueue-2fa.appspot.com",
  messagingSenderId: "857079954092",
  appId: "1:857079954092:web:e15075d74d71a93dc5bd09",
  measurementId: "G-Y1YF4MZZF0",
  databaseUrl: "https://iqueue-2fa-default-rtdb.firebaseio.com/",
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth()
const db = getDatabase()
const provider = new GoogleAuthProvider()

const addUserIfNonExistent = async (userCredential, addGlobalError) => {
  try {
    let user = await get(ref(db, 'users/' + userCredential.uid))
    user = user.val()

    if (!user) {
      return await set(ref(db, 'users/' + userCredential.uid), {
        created_at: new Date().toISOString(),
        title: userCredential.displayName,
        photo: userCredential.photoURL,
      })
    }
  } catch (e) {
    console.log(e.message)
    if (e.message.includes('Permission denied')) {
      addGlobalError(ERROR_ACCOUNT_NO_PERMISSIONS)
    } else {
      throw e
    }
  }
}

function syncUser(user, addGlobalError) {
  return get(ref(db, 'users/' + user.uid))
    .then((userData) => {
      const data = userData.val()
      const role = data.role
      const companies = data.companies || []

      if (!role) {
        addGlobalError(ERROR_ACCOUNT_NO_PERMISSIONS)
      }

      bus.emit('auth-changed', {
        ...user,
        role,
        companies,
      })
    })
    .catch(
      // () => bus.emit('auth-changed', null)
      noop
    )
}

onAuthStateChanged(auth, (newUser) => {
  if (newUser) {
    syncUser(newUser).catch(noop)
  } else {
    bus.emit('auth-changed', null)
  }
})

export function useAuth() {
  const { user, setUser, addGlobalError } = useProps()

  const logout = useCallback(() => {
    setUser(null)
    signOut(auth).catch(noop)
  }, [])

  useEffect(() => {
    const listener = (newUser) => {
      if (!newUser) return logout()
      if (user && newUser && user.uid === newUser.uid) return
      setUser(newUser)
    }

    bus.on('auth-changed', listener)
    return () => {
      bus.off('auth-changed', listener)
    }
  }, [user, logout])

  const login = useCallback(async (getUser) => {
    try {
      await setPersistence(auth, browserLocalPersistence)
      const user = await getUser()
      await addUserIfNonExistent(user, addGlobalError)
      await syncUser(user, addGlobalError)
    } catch (e) {
      let message = e.message
      if (message.includes('auth/network-request-failed')) message = 'Проверьте соединение'
      if (message.includes('auth/user-not-found')) message = 'Пользователь не найден'
      if (message.includes('auth/user-disabled')) return addGlobalError(ERROR_ACCOUNT_NOT_ACTIVATED)
      if (message.includes('auth/unauthorized-domain')) message = 'Неразрешенный домен!'

      EMessage(message)
      logout()
    }
  }, [user, logout, addGlobalError])

  const loginWithGoogle = useCallback(async () => {
    await login(async () => prop('user', await signInWithPopup(auth, provider)))
  }, [user, logout])

  const loginWithPassword = useCallback(async ({ email, password }) => {
    await login(async () => prop('user', await signInWithEmailAndPassword(auth, email, password)))
  }, [user, logout])

  return { user, loginWithGoogle, loginWithPassword, logout }
}

async function sendScanData(props) {
  const time = new Date()
  props = mapObjIndexed((v) => v === undefined ? 'N/A' : v, props)

  await set(push(ref(db, `history/${ time.getFullYear() }-${ time.getMonth() }-${ time.getDate() }`)), {
    ...props,
    id: UUID(),
    user_id: auth.currentUser.uid,
    user_photo: auth.currentUser.photoURL,
    user_title: auth.currentUser.displayName,
  })
}

const getUsers = (cb) => {
  return onValue(ref(db, 'users'), (snapshot) => {
    cb(fbObjectToArray(snapshot.val()))
  })
}

const saveUsersUpdates = async data => {
  const updates = {}
  data.forEach(item => {
    updates['/' + item.key] = { ...item, key: null }
  })

  console.log(updates)
  // update(ref(db, 'users'), updates)
  //   .then(() => {
  //     Message({
  //       title: 'Success',
  //       subtitle: 'Data has been updated',
  //       timeout: 5000,
  //       type: 'success'
  //     })
  //   })
}

function getHistory(date = moment(), cb) {
  const path = `history/${ date.year() }-${ date.month() }-${ date.date() }`
  return onValue(ref(db, path), (snapshot) => {
    const items = fbObjectToArray(snapshot.val()) || []
    cb(items.sort((a, b) => new Date(b.systemTime).getTime() - new Date(a.systemTime).getTime()))
  })
}

export {
  app, analytics, getHistory, sendScanData, getUsers, saveUsersUpdates,
}
