import React, { useEffect } from 'react'
import { Window, Router, Button } from '@iq/iq-ui-kit'
import cn from 'classnames'
import QRCodeScanner from './views/QRCodeScanner'
import Login from "./views/Login"
import Footer from './components/Footer'
import History from './views/History'
import useRouter from './hooks/useRouter'
import Users from './views/Users'
import withNav from './components/withNav'

import './style/App.scss'
import { useAuth } from './api/firebase'
import useProps from './hooks/useProps'
import Error from './components/Error'

function App({ nav }) {
  const { user, logout } = useAuth()
  const { route, navigate, canPopBack, popBack } = useRouter()
  const { globalError, historyDate, prevDay, nextDay } = useProps()

  return <Window
    keepFooter
    keepHeader
    nav={ nav }
    route={ route }
    footer={ <Footer/> }
    touchActions={ false }
    onBackClick={ popBack }
    useBackArrow={ canPopBack }
    title={ route === 'history' ? historyDate.format('LL') : 'QMS iQueue Аутентификатор' }
    className={ cn('iq-scanner-window', { 'no-paddings': route !== 'scanner' }) }
    scrollbars={ route !== 'scanner' || !globalError }
    onNavigate={ (route) => navigate(route, true) }
    extra={ <>
      { !!user && globalError.length === 0 && route === 'history' && <>
        <Button flat icon={ 'arrow_back' } onClick={ prevDay }/>
        <Button flat icon={ 'arrow_forward' } onClick={ nextDay }/>
      </> }
      { user && <Button flat icon={ 'logout' } onClick={ logout }/> }
    </>
    }
  >
    { globalError.length > 0 ? <Error /> : user ? <Router
      route={ route }
      routes={ {
        'users': () => <Users/>,
        'history': () => <History/>,
        'scanner': () => <QRCodeScanner/>,
      } }
      notFound={ <div>404 NF!</div> }
    /> : <Login/> }
  </Window>
}

export default withNav(App)
