import { Image } from '@iq/iq-ui-kit'
import moment from 'moment'

function m(value) {
  return moment(value).format('LTS')
}

export default function userToItem(item) {
  const { key, created_at, title, role, photo, deleted_at } = item

  return {
    disabled: role === 'admin',
    key: key,
    title: (title || '') + (item.role === 'admin' ? ' (Admin)' : ''),
    subtitle: deleted_at ? 'deleted at ' + m(deleted_at) : 'created at ' + m(created_at),
    avatar: <Image
      placeholder={ 'AA' }
      style={ {
        background: 'var(--iq-divider)',
        color: 'var(--iq-color)',
        width: '4rem',
        height: '4rem',
        fontSize: '1.6rem',
        fontWeight: 700,
        borderRadius: '50%',
      } }
      src={ photo }
    />,
  }
}
