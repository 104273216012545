import { Icon, Row, Button } from '@iq/iq-ui-kit'
import { useMemo } from 'react'
import useProps from '../hooks/useProps'
import { head } from 'ramda'
import EMessage from '../events/eMessage'

export const ERROR_OFFLINE = 'offline'
export const ERROR_NO_GPS = 'gps'
export const ERROR_NO_CAMERA = 'camera'
export const ERROR_ACCOUNT_NO_PERMISSIONS = 'account-permissions'
export const ERROR_ACCOUNT_NOT_ACTIVATED = 'deactivated-account'
export const ERROR_ACCOUNT_DELETED = 'deleted-account'
export const ERROR_UNSUPPORTED_BROWSER = 'unsupported-browser'
export const ERROR_UNSECURED_CONNECTION = 'unsecured-connection'

function checkPermission(name, error, addGlobalError, removeGlobalError) {
  navigator.permissions.query({ name })
    .then((permissionObj) => {
      if (permissionObj.state !== 'granted') {
        addGlobalError(error)
      } else {
        EMessage('Статус: ' + permissionObj.state)
        removeGlobalError(error)
      }
    })
    .catch((e) => {
      addGlobalError(error)
      EMessage('Ошибка доступа', e.message)
    })
}

export default function Error() {
  const { addGlobalError, removeGlobalError, globalError } = useProps()

  const { icon, message, action, actionTitle } = useMemo(() => {
    const error = head(globalError)

    switch (error) {
      case ERROR_NO_GPS: return {
        icon: 'location_off',
        message: 'Нет досутпа к местоположению',
        action: () => checkPermission('geolocation', ERROR_NO_GPS, addGlobalError, removeGlobalError)
      }
      case ERROR_NO_CAMERA: return {
        icon: 'camera_alt',
        message: 'Нет досутпа к камере',
        action: () => checkPermission('camera', ERROR_NO_CAMERA, addGlobalError, removeGlobalError)
      }
      case ERROR_OFFLINE: return { icon: 'perm_scan_wifi', message: 'Нет досутпа к интернету' }
      case ERROR_UNSECURED_CONNECTION: return { icon: 'public_off', message: 'Небезопасное соединение' }
      case ERROR_ACCOUNT_NO_PERMISSIONS: return { icon: 'manage_accounts', message: 'Необходима активация администратором' }
      //ERROR_ACCOUNT_NO_PERMISSIONS no_accounts
      default: {
        console.warn('Unsupported error:', error)
        return { icon: 'error', message: 'Неизвестная ошибка' }
      }
    }
  }, [globalError, addGlobalError, removeGlobalError])

  return <>
    <Row align={ 'center' } justify={ 'center' }>
      <Icon style={ {
        fontSize: '33vmin',
        marginTop: '10vmin',
        marginBottom: '1.6rem',
        color: 'var(--iq-outline)',
      } }>{ icon }</Icon>
    </Row>

    <Row align={ 'center' }  justify={ 'center' }>
      <h5>{ message }</h5>
    </Row>

    { !!action && <Row align={ 'center' }  justify={ 'center' }>
      <Button onClick={ action } primary>{ actionTitle || 'Предоставить доступ' }</Button>
    </Row> }
  </>
}
