import { useMemo, useEffect } from 'react'
import { propEq, find, prop, propOr } from 'ramda'
import useRouter from '../hooks/useRouter'
import { useAuth } from '../api/firebase'
import EMessage from '../events/eMessage'

const iconForRoute = {
  'scanner': 'qr_code_scanner',
  'history': 'history',
  'users': 'manage_accounts',
}

const routeTitles = {
  'scanner': 'QR сканер',
  'history': 'История',
  'users': 'Пользователи',
}

const roleTitles = {
  admin: 'Администратор',
  user: 'Все роли',
}

const groups = [{
  title: 'admin',
  routes: ['history', 'users'],
}, {
  title: 'user',
  routes: ['scanner'],
}]

export default function withNav(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const ComponentWithNav = (props) => {
    const { route, navigate } = useRouter()
    const { user, logout } = useAuth()

    const userRole = useMemo(() => {
      if (!user) return 'guest'

      let userRole = user.role
      if (!find(propEq('title', userRole), groups)) {
        userRole = 'user'
      }

      return userRole
    }, [user])

    const nav = useMemo(() => {
      if (!user) return null
      let firstGroup = true

      return groups.map(({ title, routes }) => {
        if (userRole !== 'admin' && userRole !== title) return []

        const out = routes.map((r) => ({
          key: title + r,
          route: r,
          title: routeTitles[r],
          icon: iconForRoute[r],
        }))

        if (firstGroup && userRole !== 'admin') {
          firstGroup = false
        } else {
          out.unshift({
            title: userRole === 'admin' ? roleTitles[title] : undefined,
            key: title,
            divider: true,
          })
        }

        return out
      }).flat()
    }, [user, userRole])

    useEffect(() => {
      if (!user) return

      let routes
      if (userRole !== 'admin') {
        routes = propOr([], 'routes', find(propEq('title', userRole), groups))
      } else {
        routes = groups.map(prop('routes')).flat()
      }

      if (!routes.length) {
        EMessage('Недоступно для вашего аккаунта!')
        return logout()
      }

      if (!routes.includes(route)) {
        return navigate(routes[0], true)
      }
    }, [navigate, route, logout, userRole])

    return <WrappedComponent
      nav={ nav }
      { ...props }
    />
  }

  ComponentWithNav.displayName = `withNav(${displayName})`
  return ComponentWithNav
}
