import { Message } from '@iq/iq-ui-kit'

export default function EMessage(title, subtitle = undefined) {
  return Message({
    title,
    subtitle,
    type: 'error',
    timeout: 10000,
  })
}
