import React, { useCallback } from 'react'
import { Footer as F, Layout } from '@iq/iq-ui-kit'
import useRouter from '../hooks/useRouter'
import StatisticFooter from './StatisticFooter'
import DefaultFooter from './DefaultFooter'
import UsersFooter from './UsersFooter'

export default function Footer() {
  const { route } = useRouter()

  const renderFooterContent = useCallback(() => {
    switch (route) {
      case '/statistic':
        return <StatisticFooter/>
      default:
        return <DefaultFooter/>
    }
  }, [route])

  return route === '/users' ? <UsersFooter/> : <F>
    <Layout horizontal style={ {
      background: 'transparent',
      alignItems: 'center',
      marginBottom: 0,
    } }>
      { renderFooterContent() }
    </Layout>
  </F>
}
