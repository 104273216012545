import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import moment from 'moment'
import 'moment/locale/ru'
import { ContextProvider } from './hooks/useProps'
import App from './App'

import '@iq/iq-ui-kit/lib/iq-ui-kit.css'
import '@iq/iq-ui-kit/lib/roboto.css'
import '@iq/iq-ui-kit/lib/icons.css'
import './style/index.css'
import { register } from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { Message } from '@iq/iq-ui-kit'

moment.locale('ru')

ReactDOM
  .createRoot(document.querySelector("#root"))
  .render(<StrictMode>
    <ContextProvider>
      <App/>
    </ContextProvider>
  </StrictMode>)

register({
  onUpdate: () => {
    Message({
      timeout: 5000,
      title: 'SW Updated!',
      type: 'success',
    })
  },
  onSuccess: () => {
    Message({
      timeout: 5000,
      title: 'SW Installed!',
      type: 'success',
    })
  }
})

reportWebVitals()
