import { pipe, mapObjIndexed, values } from 'ramda'

const fbObjectToArray = pipe(
  mapObjIndexed((value, key) => ({
    ...value,
    key,
  })),
  values,
)

export default fbObjectToArray
