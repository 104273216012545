import { List, View } from '@iq/iq-ui-kit'
import { getHistory } from '../api/firebase'
import { useEffect, useState } from 'react'
import historyToItem from '../mappers/historyToItem'
import useProps from '../hooks/useProps'

const History = () => {
  const { historyDate } = useProps()
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems([])
    getHistory(historyDate, setItems)
  }, [historyDate])

  return <View content={ items }>
    { (items) => <List
      selectionMode={ 'none' }
      items={ items }
      render={ historyToItem }/> }
  </View>
}

export default History
