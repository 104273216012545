import { Button } from '@iq/iq-ui-kit'
import bus from '../events/bus'

export default function UsersFooter() {
  return <Button
    primary
    flat
    style={ { width: '100%', bottom: 0, padding: 15, margin: 0 } }
    title="Save"
    onClick={ () => bus.emit('save-users') }
  />
}
