import { List, View } from '@iq/iq-ui-kit'
import userToItem from '../mappers/userToItem'
import { getUsers } from '../api/firebase'
import { useState, useEffect } from 'react'
import { saveUsersUpdates } from '../api/firebase'
import bus from '../events/bus'
import { assoc } from 'ramda'

const Users = () => {
  const [items, setItems] = useState([])

  useEffect(() => {
    getUsers(res => setItems(res))
  }, [])

  useEffect(() => {
    const saveUsers = () => {
      saveUsersUpdates(items.map(assoc('role', 'engineer'))).catch(console.error)
    }

    bus.on('save-users', saveUsers)
    return () => bus.off('save-users', saveUsers)
  }, [items])

  return <>
    <View content={ items }>
      { (items) => {
        return <>
          <List
            selected={ items.filter(item => item.role === 'engineer') }
            selectionMode={ "multiple" }
            onSelect={ (items) => {
              setItems(values => values.map(el => {
                if (items.find(el1 => el1.key === el.key)) {
                  return { ...el, role: 'engineer' }
                } else {
                  return { ...el, role: null }
                }
              }))
            } }
            items={ items }
            render={ userToItem }/>
        </>
      } }
    </View>
  </>
}

export default Users
