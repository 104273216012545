import { Button, ModalDate } from '@iq/iq-ui-kit'
import moment from 'moment'
import React from 'react'
import useProps from '../hooks/useProps'

export default function StatisticFooter() {
  const { historyDate, prevDay, nextDay, setFullDate } = useProps()

  return <div style={ { width: '100%', display: 'flex', justifyContent: 'space-between' } }>
    <Button
      flat inline
      title={ moment(historyDate).format('LL') }
      onClick={ () => {
        ModalDate
          .show({
            date: moment(historyDate),
          })
          .then((data) => {
            setFullDate(data.toDate())
          })
      } }
    />

    <Button.Group className="iq-pagination-page-nav">
      <Button
        icon="chevron_left"
        onClick={ prevDay }
      />

      <Button
        icon="chevron_right"
        onClick={ nextDay }
      />
    </Button.Group>
  </div>
}
