import { useCallback } from 'react'
import { append, init, last } from 'ramda'
import useProps from './useProps'

const useRouter = () => {
  const { route, setRoute, prevRoutes, setPrevRoutes } = useProps()

  const navigate = useCallback((route, clearHistory) => {
    setRoute(prevValue => {
      setPrevRoutes((routes) => {
        let newRoutes = []

        if (!clearHistory) {
          newRoutes = last(routes) === prevValue ? routes : append(prevValue, routes)
        }

        return newRoutes
      })

      return route
    })
  }, [])

  const popBack = useCallback(() => {
    setPrevRoutes((routes) => {
      if (routes.length) setRoute(last(routes))
      return init(routes)
    })
  }, [])

  const canPopBack = useCallback(() => {
    return !!prevRoutes.length
  }, [prevRoutes])

  const prevRoute = useCallback(() => {
    return last(prevRoutes)
  }, [prevRoutes])

  const clearHistory = useCallback(() => {
    setPrevRoutes([])
  }, [])

  return { route, navigate, popBack, canPopBack, clearHistory, prevRoute }
}

export default useRouter
